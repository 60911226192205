import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
//import Search from '../components/search'
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"

const IndexPage = () => {

    if( !isAuthenticated() ) {
        return(
            <Layout>
                <div className="container">
                    <nav className="menu loginout login">
                        <a
                            href="#!login"
                            onClick={e => {
                            login()
                            e.preventDefault()
                            }}
                        >
                            Log In
                        </a>
                    </nav>
                </div>
            </Layout>
        )
    }

    const user = getProfile()
    return (
        <Layout>
            <div className="container">

                <h3 className="welcome">Ciao, {user.name ? user.name : "amico"}!</h3>
                <nav className="menu loginout logout">
                    <ul className="items">
                        <li className="item">
                            <Link to="/products/">Prodotti</Link>
                        </li>
                        <li className="item">
                            <a
                                href="#!logout"
                                onClick={e => {
                                logout()
                                e.preventDefault()
                                }}
                            >
                                Log Out
                            </a>
                        </li>
                    </ul>
                </nav>

            </div>
        </Layout>
    )

}

export default IndexPage